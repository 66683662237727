* {
  box-sizing: border-box;

  font-family: Arial, Helvetica, sans-serif;
  /* font-family: "Manrope", sans-serif; */
}

body {
  margin: 0;
  box-sizing: border-box;
  /* font-family: "Roboto Slab", serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
